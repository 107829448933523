import React from "react";
import Typer from "../../Components/Typer";
import FadeIn from "../../Components/FadeIn";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import EmailIcon from "../../Components/assets/email.svg";
import ChevronDownIcon from "../../Components/assets/chevron--down.svg";
import { validateEmail } from "../../Utils/StringUtil";
import { useRouter } from "next/router";
import debounce from "lodash/debounce";

interface Props {
  formName: string;
  Endpoint: string | null;
  Description: string | null;
  FallbackEmail: string | null;
  index: number;
  Dropdown: {
    Title: string | null;
    DropdownValue: {
      Value: string;
    }[];
  }[];
  Input: {
    IsHidden: boolean | null;
    IsRequired: boolean | null;
    Placeholder: string | null;
    Prefill: string | null;
    Title: string | null;
    InputType: "text" | "email" | "phone" | "textarea" | null;
  }[];
}

interface FormInput {
  [key: string]: {
    required: boolean;
    value: string;
    isEmail?: boolean;
  };
}

const ContactForm: React.FC<Props> = (props) => {
  const { asPath } = useRouter();
  const successRef = React.useRef<HTMLDivElement>(null);
  const [formReady, setFormReady] = React.useState(false);
  const [formSuccess, setFormSuccess] = React.useState(false);
  const [formInputs, setFormInputs] = React.useState<FormInput>(
    props.Input.length
      ? props.Input.reduce((acc, obj) => {
          const formInputs: FormInput = { ...acc };
          const key = obj.Title ? obj.Title.replace(/\s/g, "") : "";
          if (key) {
            formInputs[key] = {
              isEmail: obj.InputType === "email",
              required: obj.IsRequired ? obj.IsRequired : false,
              value: obj.Prefill ? obj.Prefill : "",
            };
          }
          return { ...formInputs };
        }, {})
      : {}
  );
  const [dropdownInputs, setDropdownInputs] = React.useState<FormInput>(
    props.Dropdown.length
      ? props.Dropdown.reduce((acc, obj) => {
          const formInputs: FormInput = { ...acc };
          const key = obj.Title ? obj.Title.replace(/\s/g, "") : "";
          if (key && obj.DropdownValue[0].Value) {
            formInputs[key] = {
              required: true,
              value: obj.DropdownValue[0].Value?.replace(/\s/g, ""),
            };
          }
          return { ...formInputs };
        }, {})
      : {}
  );

  function formChecker() {
    let isReady = true;
    Object.keys(formInputs).forEach((inputName) => {
      if (!formInputs[inputName].value) {
        isReady = false;
      } else {
        if (formInputs[inputName].isEmail) {
          isReady = validateEmail(formInputs[inputName].value);
        }
      }
    });
    Object.keys(dropdownInputs).forEach((inputName) => {
      if (!dropdownInputs[inputName].value) {
        isReady = false;
      }
    });
    setFormReady(isReady);
  }

  const debouncedFormChecker = React.useRef(
    debounce(() => {
      formChecker();
    }, 60)
  ).current;

  function handleInputOnChange(
    target: string,
    value: string,
    type: "input" | "dropdown"
  ) {
    if (type === "input") {
      const newFormInputs = { ...formInputs };
      if (newFormInputs[target]) {
        newFormInputs[target].value = value;
      }
      setFormInputs(newFormInputs);
    }
    if (type === "dropdown") {
      const newDropdownInputs = { ...dropdownInputs };
      if (newDropdownInputs[target]) {
        newDropdownInputs[target].value = value;
      }
      setDropdownInputs(newDropdownInputs);
    }
    debouncedFormChecker();
  }

  React.useEffect(() => {
    const hash = asPath.split("#");
    if (hash.length > 1) {
      const hashes = hash[1].split("&");
      const preselectRegex = /[^\]]*preselect\[[^\]]*\]/;
      const newDropdownInputs = { ...dropdownInputs };
      let isFormSuccess = false;
      hashes.forEach((hash) => {
        //Check for success.
        if (hash === "contactSuccess") {
          isFormSuccess = true;
        }
        //Check for preselect dropdowns.
        const preselectMatch = hash.match(preselectRegex);
        if (preselectMatch && preselectMatch[0]) {
          const nameValueStr = preselectMatch[0].match(/(?<=\[)[^\]]*/);
          if (nameValueStr && nameValueStr[0]) {
            const nameValue = nameValueStr[0].split("=");
            if (nameValue.length === 2) {
              if (newDropdownInputs[nameValue[0]]) {
                let valid = false;
                props.Dropdown.forEach((d) => {
                  if (d.Title && d.Title.replace(/\s/g, "") === nameValue[0]) {
                    d.DropdownValue.forEach((v) => {
                      if (v.Value === nameValue[1]) {
                        valid = true;
                      }
                    });
                  }
                });
                if (valid) {
                  newDropdownInputs[nameValue[0]].value = nameValue[1];
                }
              }
            }
          }
        }
      });
      setFormSuccess(isFormSuccess);
      setDropdownInputs(newDropdownInputs);
    }
  }, [asPath]);

  React.useEffect(() => {
    if (formSuccess && successRef.current) {
      successRef.current.scrollIntoView();
    }
  }, [formSuccess]);

  return (
    <>
      <div className="_2-col">
        <div className="col">
          <div className="h2">
            <Typer vizTrigger text="Get in touch" />
          </div>
          <FadeIn>
            <div className="heading-paragraph-container">
              {props.Description ? (
                <p className="big-text">
                  {props.Description}{" "}
                  {props.FallbackEmail && validateEmail(props.FallbackEmail) ? (
                    <a className="link" href={`mailto:${props.FallbackEmail}`}>
                      {props.FallbackEmail}
                    </a>
                  ) : null}
                </p>
              ) : null}
            </div>
          </FadeIn>
        </div>
        {props.Endpoint ? (
          <div className="col">
            <FadeIn>
              <form
                className="contact-form"
                action={props.Endpoint}
                method="POST"
                id={props.formName}
                name={props.formName}
              >
                {props.Dropdown && props.Dropdown.length
                  ? props.Dropdown.map((d, i) => {
                      if (d.Title && d.DropdownValue.length) {
                        const inputName = d.Title.replace(/\s/g, "");
                        return (
                          <label
                            className="input-container"
                            key={`${inputName}-${i}`}
                          >
                            <h4>{d.Title}</h4>
                            <div className="select-container">
                              <ChevronDownIcon />
                              <select
                                onChange={(e) => {
                                  handleInputOnChange(
                                    inputName,
                                    e.target.value,
                                    "dropdown"
                                  );
                                }}
                                name={inputName}
                                className="select"
                                value={dropdownInputs[inputName].value}
                              >
                                {d.DropdownValue.map((option, j) => {
                                  if (option.Value) {
                                    return (
                                      <option
                                        key={`${inputName}-${i}-${j}`}
                                        value={option.Value.replace(/\s/g, "")}
                                      >
                                        {option.Value}
                                      </option>
                                    );
                                  }
                                  return null;
                                })}
                              </select>
                            </div>
                          </label>
                        );
                      }
                      return null;
                    })
                  : null}
                {props.Input && props.Input.length
                  ? props.Input.map((input, i) => {
                      if (input.Title) {
                        const inputName = input.Title.replace(/\s/g, "");
                        const kind =
                          input.InputType && input.InputType !== "textarea"
                            ? input.InputType
                            : "text";
                        return (
                          <Input
                            key={`${inputName}-${i}`}
                            name={input.Title.replace(/\s/g, "")}
                            label={input.Title}
                            onChange={(
                              e: React.FormEvent<HTMLInputElement>
                            ) => {
                              if (input.Title) {
                                handleInputOnChange(
                                  input.Title.replace(/\s/g, ""),
                                  e.currentTarget.value,
                                  "input"
                                );
                              }
                            }}
                            isTextarea={input.InputType === "textarea"}
                            kind={kind === "phone" ? "tel" : kind}
                            value={formInputs[inputName].value}
                            placeholder={
                              input.Placeholder ? input.Placeholder : ""
                            }
                          />
                        );
                      }
                    })
                  : null}
                <Button
                  disabled={!formReady}
                  renderIcon={<EmailIcon />}
                  isSubmit
                  text="Send"
                />
              </form>
            </FadeIn>
            {formSuccess ? (
              <p className="contact-success" ref={successRef}>
                Thank you for reaching out. We&#39;ll get back to you soon as
                possible.
              </p>
            ) : null}
          </div>
        ) : (
          <div className="col empty" />
        )}
      </div>
    </>
  );
};

export default ContactForm;
