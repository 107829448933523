import React from "react";
import FadeIn from "../../Components/FadeIn";
import Splitter from "../../Components/Splitter";
import { SectionProps } from "../../@types/Section";
import Typer from "../../Components/Typer";
import Image from "next/image";
import { getStrapiMedia } from "../../strapi/media";
import LinkWrapper from "../../Components/Link";

interface Props extends SectionProps {
  SectionTitle: string;
  index: number;
  Items: {
    Title: string | null;
    Description: string | null;
    Subtitle?: string | null;
    Image: any;
    Url1?: string | null;
    Url2?: string | null;
    Url3?: string | null;
    Url1Title?: string | null;
    Url2Title?: string | null;
    Url3Title?: string | null;
    IsBlackAndWhiteImage?: boolean;
  }[];
}

const PhotoList: React.FC<Props> = (props) => {
  const itemsCount = props.Items.length;

  return (
    <>
      {props.SectionTitle ? (
        <>
          <h3 className="h1 standalone-title">
            <Typer vizTrigger text={props.SectionTitle} />
          </h3>
          <Splitter vizTrigger />
        </>
      ) : null}

      {props.Items && props.Items.length
        ? props.Items.map((item, idx) => {
            const listItem = [
              <div className="_2-col" key={`${props.index}-${idx}-photolist`}>
                <div className="col">
                  <div className="h2">
                    {item.Title ? <Typer vizTrigger text={item.Title} /> : null}
                  </div>
                </div>
                <div className="col">
                  <FadeIn>
                    <div
                      className={`image-text-horizontal-container ${
                        item.IsBlackAndWhiteImage ? "investor-logo" : ""
                      }`}
                    >
                      <div className="image-container">
                        {item.Image ? (
                          <Image
                            alt={
                              item.Image.data.attributes.caption
                                ? item.Image.data.attributes.caption
                                : "Caption for image is missing"
                            }
                            src={getStrapiMedia(item.Image)}
                            height={item.Image.data.attributes.height}
                            width={item.Image.data.attributes.width}
                          />
                        ) : null}
                      </div>
                      <div>
                        {item.Subtitle ? (
                          <h4 className="name-title">{item.Subtitle}</h4>
                        ) : null}
                        {item.Description ? (
                          <div className="paragraphs-container">
                            {item.Description.split("\n\n").map((s, idx2) => (
                              <div
                                className="paragraph"
                                key={`pld-${props.index}-${idx2}`}
                              >
                                <p>{s}</p>
                              </div>
                            ))}
                          </div>
                        ) : null}
                        <div className="paragraph-link-container">
                          {item.Url1 && item.Url1Title ? (
                            <div className="link-container">
                              <LinkWrapper
                                href={item.Url1}
                                text={item.Url1Title}
                                label={item.Url1Title}
                              />
                            </div>
                          ) : null}
                          {item.Url2 && item.Url2Title ? (
                            <div className="link-container">
                              <LinkWrapper
                                href={item.Url2}
                                text={item.Url2Title}
                                label={item.Url2Title}
                              />
                            </div>
                          ) : null}
                          {item.Url3 && item.Url3Title ? (
                            <div className="link-container">
                              <LinkWrapper
                                href={item.Url3}
                                text={item.Url3Title}
                                label={item.Url3Title}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </FadeIn>
                </div>
              </div>,
            ];
            if (idx !== itemsCount - 1) {
              listItem.push(
                <Splitter
                  vizTrigger
                  key={`${props.index}-${idx}-photolist-splitter`}
                />
              );
            }
            return listItem;
          })
        : null}
    </>
  );
};

export default PhotoList;
